import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { EMPTY, Observable, catchError, map, of } from 'rxjs';
import { UserModel } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { TelegramService } from '../services/telegram.service';
import { LocalStorageService } from '../services/local-storage.service';

export const userResolver: ResolveFn<UserModel> = (route, state) => {
    const authService = inject(AuthService);
    const telegramService = inject(TelegramService);
    const localStorageService = inject(LocalStorageService);
    const router = inject(Router);

    telegramService.init();
    const tgData = telegramService.tg.initData;

    return <Observable<UserModel>>authService.auth(tgData).pipe(
        map((data) => {
            const tutorialStatus =
                localStorageService.getItem('tutorialStatus');

            if (tutorialStatus !== 'completed' && state.url === '/') {
                router.navigate(['/tutorial']).then();
            }

            return data;
        }),
        catchError(() => {
            return of(EMPTY);
        })
    );
};
